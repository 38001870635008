<template>
  <div class="">
    <div class="col-12">
      <div class="card">
        <div class="mb-3 text-xl font-medium text-black">
          <p>Pesanan</p>
        </div>
        <DataTable
          :value="invoice"
          :paginator="true"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          v-model:filters="filters1"
          filterDisplay="menu"
          :filters="filters1"
          responsiveLayout="scroll"
          :globalFilterFields="[
            'no',
            'salon_name',
            'customer_name',
            'invoice_number',
            'status',
            'amount',
          ]"
          @row-click="toDetail"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="perPageOptions"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        >
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <Button
                type="button"
                icon="pi pi-filter-slash"
                label="Hapus Filter"
                class="mb-2 p-button-outlined"
                @click="clearFilter()"
              />
              <span class="mb-2 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters1['global'].value"
                  placeholder="Kata kunci"
                  style="width: 100%"
                />
              </span>
            </div>
          </template>
          <template #empty>
            <div
              class="tw-flex tw-justify-center tw-items-center tw-py-20 tw-text-2xl tw-font-bold"
            >
              Tidak ada data yang ditemukan
            </div>
          </template>
          <template #loading>
            <div class="tw-flex tw-justify-center tw-items-center tw-py-20">
              Loading records, please wait...
            </div>
          </template>
          <Column field="no" header="No." style="min-width: 2rem">
            <template #body="{ data }">
              {{ data.no }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by number"
              />
            </template>
          </Column>
          <Column
            header="Nama Salon"
            filterField="salon_name"
            style="min-width: 12rem"
          >
            <template #body="{ data }">
              {{ data.salon_name }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by salon name"
              />
            </template>
          </Column>
          <Column
            header="Nama Customer"
            filterField="customer_name"
            style="min-width: 12rem"
          >
            <template #body="{ data }">
              {{ data.customer_name }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by customer name"
              />
            </template>
          </Column>
          <Column
            header="No. Invoice"
            filterField="invoice_number"
            style="min-width: 22rem"
          >
            <template #body="{ data }">
              {{ data.invoice_number }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by invoice number"
              />
            </template>
          </Column>
          <Column header="Status" filterField="status" style="min-width: 11rem">
            <template #body="{ data }">
              <Tag class="mr-2" severity="warning" value="Menunggu diproses" v-if="data.status === 0"></Tag>
              <Tag class="mr-2" severity="info" value="Belum dibayar" v-else-if="data.status === 1"></Tag>
              <Tag class="mr-2" severity="success" value="Sudah dibayar" v-else-if="data.status === 2"></Tag>
              <Tag severity="danger" value="Dibatalkan" v-else-if="data.status === 3"></Tag>
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by status"
              />
            </template>
          </Column>
          <Column header="Harga" filterField="amount" style="min-width: 12rem">
            <template #body="{ data }">
              {{ formatCurrency(data.amount) }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by number"
              />
            </template>
          </Column>
          <Column
            header="Aksi"
            :showFilterMatchModes="false"
            :filterMenuStyle="{ width: '14rem' }"
            style="min-width: 8rem"
          >
            <template #body="{ data }">
              <Button
                icon="pi pi-eye"
                class="p-button-rounded p-button-info"
                @click="detailData(data.id)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import InvoiceService from '@/service/InvoiceService';
import { useCommonUtils } from '@/composables/utils';
export default {
  setup() {
    const { formatCurrency } = useCommonUtils();
    return { formatCurrency };
  },
  props: {
    perPageOptions: {
      type: Array,
      required: false,
      default() {
        return [10, 20, 50];
      },
    },
  },
  data() {
    return {
      invoice: [],
      filters1: null,
    };
  },
  invoiceService: null,
  created() {
    this.invoiceService = new InvoiceService();
    this.initFilters();
  },
  mounted() {
    this.loadData();
  },
  methods: {
    detailData(id) {
      this.$router.push({ name: 'detail-invoice', params: { id: id } });
    },
    loadData() {
      this.invoiceService.getAll().then((response) => {
        if (response.code === 200) {
          this.invoice = response.data.invoices;
          const temp = [];
          this.invoice.forEach((invoices, idx) => {
            temp.push({ ...invoices, no: idx + 1 });
          });
          this.invoice = temp;
        }
      });
    },
    initFilters() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        no: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        salon_name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        customer_name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        invoice_number: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        status: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        amount: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
      };
    },
    clearFilter() {
      this.initFilters();
    },
    toDetail(event) {
      this.$router.push({name: 'detail-invoice', params: {id: event.data.id}})
    }
  },
};
</script>

<style scoped lang="scss"></style>
